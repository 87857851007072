
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import DefaultNavbar from "examples/Navbars/DefaultNavbar";
import CenteredFooter from "examples/Footers/CenteredFooter";
import Information from "pages/LandingPages/AboutUs/sections/Information";
import Contact from "pages/LandingPages/AboutUs/sections/Contact";
import Featuring from "pages/LandingPages/AboutUs/sections/Featuring";
import routes from "routes";
import footerRoutes from "footer.routes";
import bgImage from "assets/images/ik_logo1.png";


function AboutUs() {
  return (
    <>
      {/* <DefaultNavbar
      name=''
        routes={routes}
        transparent
        light
      /> */}
      <MKBox
        minHeight="75vh"
        width="100%"
        sx={{
          backgroundImage: ({ functions: { linearGradient, rgba }, palette: { gradients } }) =>
            `${linearGradient(
              rgba(gradients.dark.main, 0.6),
              rgba(gradients.dark.state, 0.6)
            )}, url(${bgImage})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          display: "grid",
          placeItems: "center",
        }}
      >
        <Container>
          <Grid
            container
            item
            xs={12}
            lg={8}
            justifyContent="center"
            alignItems="center"
            flexDirection="column"
            sx={{ mx: "auto", textAlign: "center" }}
          >
            <MKTypography
              variant="h1"
              color="white"
              sx={({ breakpoints, typography: { size } }) => ({
                [breakpoints.down("md")]: {
                  fontSize: size["3xl"],
                },
              
              })}
            >
              Individuality knows its place

            </MKTypography>
          
            <MKTypography variant="h6" color="white" mt={8} mb={1} mr={3}>
              Find us on !
            </MKTypography>
            <MKBox display="flex" justifyContent="center" alignItems="center">
              
              <MKTypography component="a" variant="body1" color="white" href="https://www.instagram.com/kobcon_/" mr={3}>
                <i className="fab fa-instagram" />
              </MKTypography>
              <MKTypography component="a" variant="body1" color="white" href="https://www.linkedin.com/in/mohamad-kobeissi-05223915a/" mr={3}>
                <i className="fab fa-linkedin" />
              </MKTypography>
              
            </MKBox>
          </Grid>
        </Container>
      </MKBox>
      <Card
        sx={{
          p: 2,
          mx: { xs: 2, lg: 3 },
          mt: -8,
          mb: 4,
          boxShadow: ({ boxShadows: { xxl } }) => xxl,
        }}
      >
        <Information />
       
        <Featuring/>
        
        <Contact />
        
      </Card>
      <MKBox pt={6} px={1} mt={6} >
        <CenteredFooter  />
      </MKBox>
    </>
  );
}

export default AboutUs;
