import React, { useEffect, useState } from "react";
import Cookies from 'js-cookie';
import MKBox from "components/MKBox";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import MKButton from "components/MKButton";
import Modal from "@mui/material/Modal";
import Slide from "@mui/material/Slide";
import MKTypography from "components/MKTypography";
import Divider from "@mui/material/Divider";
import cookies from "assets/images/cookies.png";

const CookieBanner = () => {
  const [showBanner, setShowBanner] = useState(false);

  useEffect(() => {
    const hasAcceptedCookies = Cookies.get("accept-cookies");

    if (!hasAcceptedCookies) {
      console.log('lalalalalla',hasAcceptedCookies)
      setShowBanner(true);
    }
  }, []);

  const acceptCookies = () => {
    Cookies.set("accept-cookies", "true", { expires: 365 });
    setShowBanner(false);
  };

  const declineCookies = () => {
    // Handle the case when cookies are declined (optional)
    setShowBanner(false);
  };
  const [show, setShow] = useState(true);
  const toggleModal = () => setShow(!show);
  return (
    showBanner && (
    <MKBox component="section" py={6}>
      <Container>
        <Modal
          open={show}
          onClose={toggleModal}
          sx={{ display: "grid", placeItems: "center" }}
        >
          <Slide direction="down" in={show} timeout={500}>
            
            <MKBox
              position="relative"
              width="450px"
              display="flex"
              flexDirection="column"
              borderRadius="xl"
              bgColor="white"
              shadow="xl"
            >
          
               
              <Grid item  ml={20} mt={4}>
            <MKBox component="img" src={cookies} alt="coinbase" width="40%" opacity={0.8} />
          </Grid>
              <MKBox
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                p={2}
              >
                <MKTypography variant="h5">
                  Hello there.. We are the Cookies !
                </MKTypography>
               
              </MKBox>
              <Divider sx={{ my: 0 }} />
              <MKBox p={2}>
                <MKTypography
                  variant="body2"
                  color="secondary"
                  fontWeight="regular"
                >
                  This website uses essential cookies to ensure its proper
                  operation and tracking cookies to understand how you interact
                  with it. The latter will be set only after consent.
                </MKTypography>
              </MKBox>
              <Divider sx={{ my: 0 }} />
              <MKBox display="flex" justifyContent="space-between" p={1.5}>
                
                <MKButton variant="gradient" color="dark" onClick={declineCookies}>
                  Reject
                </MKButton>
                <MKButton variant="gradient" color="info" onClick={acceptCookies}>
                  Accept 
                </MKButton>
              </MKBox>
            </MKBox>
          </Slide>
        </Modal>
      </Container>
    </MKBox>
    )
  );
};

export default CookieBanner;
