
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import MKBox from "components/MKBox";
import DefaultInfoCard from "examples/Cards/InfoCards/DefaultInfoCard";
import CenteredBlogCard from "examples/Cards/BlogCards/CenteredBlogCard";
import salesforce from "assets/images/smallIcons/small_salesforce.webp";
import webApplication from "assets/images/smallIcons/webapplication.png";
import webSite from "assets/images/smallIcons/website.png";
import CRM from "assets/images/smallIcons/crm.png";
import myGif from 'assets/images/gif.gif'



function Information() {

  return (
    <MKBox component="section" py={12}>
      <Container>
        <Grid container spacing={3} alignItems="center">
          <Grid item xs={12} lg={6}>
            <Grid container justifyContent="flex-start">
              <Grid item xs={12} md={6}>
                <MKBox mb={5}>
                  <DefaultInfoCard
                  icon="content_copy"
                    image={salesforce}
                    style={{ maxWidth: "80px" }}
                    title="Salesforce"
                    description="Unlock the full potential of Salesforce with our expert implementation services. We streamline the process, ensuring seamless integration, customization, and optimization. Elevate your sales and customer management effortlessly."
                  />
                </MKBox>
              </Grid>
              <Grid item xs={12} md={6}>
                <MKBox mb={5}>
                  <DefaultInfoCard
                  icon="content_copy"
                    image={webApplication} 
                    style={{ maxWidth: "140px" }}
                    title="Web applications"
                    description="Turn your web app vision into reality with our seamless implementation. We handle the technicalities, so you can focus on what matters – delivering an exceptional online experience."
                  />
                </MKBox>
              </Grid>
              <Grid item xs={12} md={6}>
                <MKBox mb={{ xs: 5, md: 0 }}>
                  <DefaultInfoCard
                  icon="content_copy"
                    image={webSite}
                    style={{ maxWidth: "80px" }}
                    title="Websites"
                    description="We bring your website ideas to life with precision and care. Our team ensures a smooth and successful implementation, so your online presence shines."
                  />
                </MKBox>
              </Grid>
              <Grid item xs={12} md={6}>
                <MKBox mb={{ xs: 5, md: 0 }}>
                  <DefaultInfoCard
                  icon="content_copy"
                    image={CRM}
                    style={{ maxWidth: "90px" }}
                    title="CRM systems"
                    description="Our experts seamlessly implement CRM systems tailored to your needs. Elevate customer relationships and drive business growth effortlessly."
                  />
                </MKBox>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} lg={4} sx={{ ml: "auto", mt: { xs: 3, lg: 0 } }}>
            
            <CenteredBlogCard
              image={myGif}
              title="Driving Digital Transformation Excellence"
              description="At Kobcon, we are architects of digital transformation, specializing in CRM systems, web applications, sales process automation, and Salesforce implementation. With a passion for innovation and a commitment to excellence, we empower businesses to thrive in the digital age."
              action={{
                type: "internal",
                route: "pages/company/about-us",
                color: "info",
                label: "find out more",
              }}
            />   
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Information;
