// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import bgImage from "assets/images/ik_logo1.png";
import CenteredFooter from "examples/Footers/CenteredFooter";
import footerRoutes from "footer.routes";
import { dark, light } from "@mui/material/styles/createPalette";
function Impressum() {
  return (
    <>
      {/* <DefaultNavbar
      name=''
        routes={routes}
        transparent
        light
      /> */}
      <MKBox
        minHeight="75vh"
        width="100%"
        sx={{
          backgroundImage: ({
            functions: { linearGradient, rgba },
            palette: { gradients },
          }) =>
            `${linearGradient(
              rgba(gradients.dark.main, 0.6),
              rgba(gradients.dark.state, 0.6)
            )}, url(${bgImage})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          display: "grid",
          placeItems: "center",
        }}
      >
        <Container>
          <Grid
            container
            item
            xs={12}
            lg={8}
            justifyContent="center"
            alignItems="center"
            flexDirection="column"
            sx={{ mx: "auto", textAlign: "center" }}
          >
            <MKTypography
              variant="h1"
              color="white"
              sx={({ breakpoints, typography: { size } }) => ({
                [breakpoints.down("md")]: {
                  fontSize: size["3xl"],
                },
              })}
            >
              Individuality knows its place
            </MKTypography>

            <MKTypography variant="h6" color="white" mt={8} mb={1} mr={3}>
              Find us on !
            </MKTypography>
            <MKBox display="flex" justifyContent="center" alignItems="center">
              <MKTypography
                component="a"
                variant="body1"
                color="white"
                href="https://www.instagram.com/kobcon_/"
                mr={3}
              >
                <i className="fab fa-instagram" />
              </MKTypography>
              <MKTypography
                component="a"
                variant="body1"
                color="white"
                href="https://www.linkedin.com/in/mohamad-kobeissi-05223915a/"
                mr={3}
              >
                <i className="fab fa-linkedin" />
              </MKTypography>
            </MKBox>
          </Grid>
        </Container>
      </MKBox>
      <Card
        sx={{
          p: 2,
          mx: { xs: 2, lg: 3 },
          mt: -8,
          mb: 4,
          boxShadow: ({ boxShadows: { xxl } }) => xxl,
        }}
      >
        <MKBox component="section" py={4}>
          <Container>
            <MKTypography variant="h1" mb={6}>
              Imprint
            </MKTypography>
            <Grid container alignItems="center" py={2}>
              <Grid item xs={12} sm={9}>
                <MKTypography variant="h2" mb={2}>
                  According to § 5 TMG
                </MKTypography>
                <MKTypography variant="body1" color="text">
                  KobCon
                </MKTypography>
                <MKTypography variant="body1" color="text">
                  c/o Imprint service Your imprint
                </MKTypography>
                <MKTypography variant="body1" color="text">
                  cStettiner Str. 41
                </MKTypography>
                <MKTypography variant="body1" color="text">
                  35410 Hungen
                </MKTypography>
                <MKTypography variant="body1" color="text">
                  Represented by: Mohamad kobeissi
                </MKTypography>
              </Grid>
            </Grid>
            <Grid container alignItems="center" py={2}>
              <Grid item xs={12} sm={9}>
                <MKTypography variant="h2" mb={2}>
                  Contact
                </MKTypography>
                <MKTypography variant="body1" color="text">
                  E-Mail: service@kobcon.com
                </MKTypography>
                <MKTypography variant="body1" color="text">
                  phone: +4915777743792
                </MKTypography>
              </Grid>
            </Grid>
            <Grid container alignItems="center" py={4}>
              <Grid item xs={12} sm={9}>
                <MKTypography variant="h2" mb={2}>
                  EU dispute resolution
                </MKTypography>
                <MKTypography variant="body1" color="text">
                  The European Commission provides a platform Online dispute
                  resolution (OS) ready:
                </MKTypography>
                <MKTypography variant="body1" color="text">
                   Our email address can be found in the
                  imprint above. Consumer dispute resolution/universal
                  arbitration board We are not willing or obliged to Dispute
                  resolution procedure before a to participate in the consumer
                  arbitration board.
                </MKTypography>
              </Grid>
            </Grid>
            <Grid container alignItems="center" py={2}>
              <Grid item xs={12} sm={9}>
                <MKTypography variant="h2" mb={2}>
                  Liability for content
                </MKTypography>
                <MKTypography variant="body1" color="text">
                  As a service provider, we are responsible for our own services
                  in accordance with Section 7 Paragraph 1 TMG Contents on these
                  pages comply with general laws responsible. According to §§ 8
                  to 10 TMG we are as However, service providers are not obliged
                  to transmit or to monitor or search for stored third-party
                  information to investigate circumstances that indicate illegal
                  activity Clues.
                </MKTypography>
                <MKTypography variant="body1" color="text">
                  Our offer contains links to external third-party websites
                  whose content we have no influence. That's why we can do not
                  assume any liability for this external content. For The
                  content of the linked pages is always the respective one
                  Providers or operators of the pages are responsible. The
                  linked pages were at the time of linking Possible legal
                  violations are checked. There was illegal content not
                  recognizable at the time of linking.
                </MKTypography>
                <MKTypography variant="body1" color="text">
                  Permanent content control of the linked pages However, there
                  is no concrete evidence of a legal violation unreasonable. If
                  legal violations become known We will remove such links
                  immediately.
                </MKTypography>
              </Grid>
            </Grid>
          </Container>
        </MKBox>
      </Card>
      <MKBox pt={6} px={1} mt={6}>
        <CenteredFooter content={footerRoutes} />
      </MKBox>
    </>
  );
}

export default Impressum;
