import React, { useState } from "react";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import MKBox from "components/MKBox";
import MKInput from "components/MKInput";
import MKButton from "components/MKButton";
import MKTypography from "components/MKTypography";
import bgImage from "assets/images/examples/contactus.webp";
import myGif from "assets/images/gif.gif";
import LoadingGif from "./LoadingGif";
// interface Lead {
//   firstName: string;
//   lastname: string;
//   phonenumber: string;
//   email: string;
//   status: string;
//   leadsource: string;
//   emailMessage: EmailMessage;
// }
// interface EmailMessage {
//   subject: string;
//   body: string;
//   status: string;
//   email: string;
// }
function Contact() {
  // Define state variables
  const [formData, setFormData] = useState({
    firstname: "",
    lastname: "",
    phonenumber: "",
    email: "",
    body: "",
    leadsource: "website",
    status: "NEW",
    subject: "jcvfdows",
  });

  const [isFormSubmitted, setIsFormSubmitted] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);

  const emailMessage = {
    subject: "From Website Contactform",
    email: formData.email,
    body: formData.body,
    status: "UNREAD",
  };
  const lead = {
    message: formData.body,
    firstname: formData.firstname,
    lastname: formData.lastname,
    phonenumber: formData.phonenumber,
    email: formData.email,
    leadsource: "website",
    status: "NEW",
  };

  // Handle form input changes
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };
  const username = 'leadcreator';
  const password = 'leadcreator';
  
  const basicAuth = 'Basic ' + btoa(username + ':' + password);
  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      // Set loading gif
      setIsSubmitting(true);
      // Make a POST request to the server with the form data
      const response = await fetch(
        "https://n8n-k4o4804.kobeissi.cc/webhook/e0ba8ff5-e862-4052-8fde-1e03ca7c21ef",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "Authorization": basicAuth
          },
          body: JSON.stringify(lead),
        }
      );

   
      console.log("response",response);
      setIsSubmitting(false);
      if (response.ok) {
        // If the request was successful, update the state to show success message
        setIsFormSubmitted(true);
        setSuccessMessage(
          "Thank you for your message" + " We will contact you soon!"
        );
      } else {
        // Handle errors, e.g., show an error message
        console.error("Failed to send message");
      }
    } catch (error) {
      console.error("Error sending message:", error);
    }
  };
  const handleSubmit2 = async (e) => {
    
    e.preventDefault();
    
    const formData = new FormData(e.target);
    const formParams = new URLSearchParams(formData).toString();
    try {
      // Set loading gif
      setIsSubmitting(true);
      // Make a POST request to the server with the form data
      // const response = await fetch(
      //   "https://n8n-k4o4804.kobeissi.cc/webhook/e0ba8ff5-e862-4052-8fde-1e03ca7c21ef",
      //   {
      //     method: "POST",
      //     headers: {
      //       "Content-Type": "application/json",
      //     },
      //     body: JSON.stringify(lead),
      //   }
      // );

      const response = await fetch('https://webto.salesforce.com/servlet/servlet.WebToLead', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
        },
        body: formParams,
        mode: 'no-cors'
      });
      console.log("response",response);
      console.log("response.text()",response.text());
      setIsSubmitting(false);
      if (response.ok) {
        // If the request was successful, update the state to show success message
        setIsFormSubmitted(true);
        setSuccessMessage(
          "Thank you for your message" + " We will contact you soon!"
        );
      } else {
        // Handle errors, e.g., show an error message
        console.error("Failed to send message");
      }
    } catch (error) {
      console.error("Error sending message:", error);
    }
  };

  return (
    <MKBox component="section" pt={3} my={4}>
      <Container>
        <Grid container item>
          <MKBox
            width="100%"
            bgColor="white"
            borderRadius="xl"
            shadow="xl"
            mb={6}
            sx={{ overflow: "hidden" }}
          >
            <Grid container spacing={2}>
              {/* Left section */}
              <Grid
                item
                xs={12}
                lg={5}
                position="relative"
                px={0}
                sx={{
                  backgroundImage: ({
                    palette: { gradients },
                    functions: { rgba, linearGradient },
                  }) =>
                    `${linearGradient(
                      rgba(gradients.dark.main, 0.8),
                      rgba(gradients.dark.state, 0.8)
                    )}, url(${bgImage})`,
                  backgroundSize: "cover",
                }}
              >
                {/* ... Left section content */}
                <MKBox
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  width="100%"
                  height="100%"
                >
                  <MKBox py={6} pr={6} pl={{ xs: 6, sm: 12 }} my="auto">
                    <MKTypography variant="h3" color="white" mb={1}>
                      Contact Information
                    </MKTypography>
                    <MKTypography
                      variant="body2"
                      color="white"
                      opacity={0.8}
                      mb={3}
                    >
                      Fill up the form and our Team will get back to you within
                      24 hours.
                    </MKTypography>
                    <MKBox display="flex" p={1}>
                      <MKTypography variant="button" color="white">
                        <i className="fas fa-phone" />
                      </MKTypography>
                      <MKTypography
                        component="span"
                        variant="button"
                        color="white"
                        opacity={0.8}
                        ml={2}
                        fontWeight="regular"
                      >
                        <a href="tel:+49176 93221083" style={{ color: "white" }}>
                          (+49) 176 93221083
                        </a>
                      </MKTypography>
                    </MKBox>
                    <MKBox display="flex" color="white" p={1}>
                      <MKTypography variant="button" color="white">
                        <i className="fas fa-envelope" />
                      </MKTypography>
                      <MKTypography
                        component="span"
                        variant="button"
                        color="white"
                        opacity={0.8}
                        ml={2}
                        fontWeight="regular"
                      >
                        <a
                          href="mailto:service@kobcon.com"
                          style={{ color: "white" }}
                        >
                          service@kobcon.com
                        </a>
                      </MKTypography>
                    </MKBox>
                    <MKBox display="flex" color="white" p={1}>
                      <MKTypography variant="button" color="white">
                        <i className="fas fa-map-marker-alt" />
                      </MKTypography>
                      <MKTypography
                        component="span"
                        variant="button"
                        color="white"
                        opacity={0.8}
                        ml={2}
                        fontWeight="regular"
                      >
                        Stettiner Straße
                        41, 35410 Hungen
                      </MKTypography>
                    </MKBox>
                    <MKBox mt={1} ml={5}>
                      <MKButton
                        variant="text"
                        color="white"
                        size="large"
                        href="https://www.linkedin.com/in/mohamad-kobeissi-05223915a/"
                      >
                        <i
                          className="fab fa-linkedin"
                          style={{ fontSize: "1.25rem" }}
                        />
                      </MKButton>

                      <MKButton
                        variant="text"
                        color="white"
                        size="large"
                        href="https://www.instagram.com/kobcon_/"
                      >
                        <i
                          className="fab fa-instagram"
                          style={{ fontSize: "1.25rem" }}
                        />
                      </MKButton>
                    </MKBox>
                  </MKBox>
                </MKBox>
              </Grid>

              {/* Right section */}
              <Grid item xs={12} lg={7}>
                <MKBox
                  component="form"
                  p={2}
                  method="post"
                  id="sample-form"
                  onSubmit={handleSubmit} // Add the form submit handler
                >
                  {isSubmitting ? (
                    <LoadingGif /> // Show the LoadingGif when submitting
                  ) : isFormSubmitted ? (
                    <MKTypography
                      variant="body1"
                      color="text"
                      style={{
                        textAlign: "center",
                        marginTop: "100px",
                        fontSize: "1.5rem",
                      }}
                    >
                      {successMessage}
                    </MKTypography>
                  ) : (
                    <>
                      <Grid item xs={12} pr={1} mb={6}>
                        <MKInput
                          id="firstname"
                          name="firstname"
                          variant="standard"
                          label="First Name"
                          placeholder="First Name"
                          InputLabelProps={{ shrink: true }}
                          fullWidth
                          value={formData.firstname}
                          onChange={handleInputChange}
                        />
                      </Grid>
                      <Grid item xs={12} pr={1} mb={6}>
                        <MKInput
                          variant="standard"
                          label="Last Name"
                          id="lastname"
                          name="lastname"
                          placeholder="Last Name"
                          InputLabelProps={{ shrink: true }}
                          fullWidth
                          value={formData.lastname}
                          onChange={handleInputChange}
                        />
                      </Grid>
                      <Grid item xs={12} pr={1} mb={6}>
                        <MKInput
                          variant="standard"
                          label="Phone Number"
                          id="phonenumber"
                          name="phonenumber"
                          placeholder="Phone Number"
                          InputLabelProps={{ shrink: true }}
                          fullWidth
                          value={formData.phonenumber}
                          onChange={handleInputChange}
                        />
                      </Grid>
                      <Grid item xs={12} pr={1} mb={6}>
                        <MKInput
                          variant="standard"
                          label="Email"
                          placeholder="Email"
                          id="email"
                          name="email"
                          InputLabelProps={{ shrink: true }}
                          fullWidth
                          value={formData.email}
                          onChange={handleInputChange}
                        />
                      </Grid>
                      <Grid item xs={12} pr={1} mb={6}>
                        <MKInput
                          variant="standard"
                          label="Your message"
                          placeholder="Your message"
                          name="body"
                          InputLabelProps={{ shrink: true }}
                          fullWidth
                          multiline
                          id="message"
                          rows={6}
                          value={formData.body}
                          onChange={handleInputChange}
                        />
                      </Grid>
                      <Grid
                        container
                        item
                        xs={12}
                        md={6}
                        justifyContent="flex-end"
                        textAlign="right"
                        ml="auto"
                      >
                        <MKButton variant="gradient" color="info" type="submit">
                          Send Message
                        </MKButton>
                      </Grid>
                    </>
                  )}
                </MKBox>
              </Grid>
            </Grid>
          </MKBox>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Contact;
