
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import MKBox from "components/MKBox";
import DefaultCounterCard from "examples/Cards/CounterCards/DefaultCounterCard";
import coinbase from "assets/images/newLogosGrey/salesforce.png";
import nasa from "assets/images/newLogosGrey/react.png";
import netflix from "assets/images/newLogosGrey/bootstrap.png";
import pinterest from "assets/images/newLogosGrey/java.png";
import spotify from "assets/images/newLogosGrey/python.png";
import vodafone from "assets/images/newLogosGrey/javascript.png";

function Featuring() {
  return (
    <MKBox  component="section"
    variant="gradient"
    bgColor="dark"
    position="relative"
    py={6}
    px={{ xs: 2, lg: 0 }}
    mx={-2}>
      <Container>
        <Grid container spacing={3} sx={{ mb: 12 }}>
          <Grid item xs={6} md={4} lg={2}>
            <MKBox component="img" src={coinbase} alt="coinbase" width="100%" opacity={0.7} />
          </Grid>
          <Grid item xs={6} md={4} lg={2}>
            <MKBox component="img" src={nasa} alt="nasa" width="100%" opacity={1} />
          </Grid>
          <Grid item xs={6} md={4} lg={2}>
            <MKBox component="img" src={netflix} alt="netflix" width="100%" opacity={0.7} />
          </Grid>
          <Grid item xs={6} md={4} lg={2}>
            <MKBox component="img" src={pinterest} alt="pinterest" width="100%" opacity={0.7} />
          </Grid>
          <Grid item xs={6} md={4} lg={2}>
            <MKBox component="img" src={spotify} alt="spotify" width="100%" opacity={0.7} />
          </Grid>
          <Grid item xs={6} md={4} lg={2}>
            <MKBox component="img" src={vodafone} alt="vodafone" width="100%" opacity={0.7} />
          </Grid>
        </Grid>
        <Grid container justifyContent="center" sx={{ textAlign: "center" }}>
          <Grid item xs={12} md={3}>
            <DefaultCounterCard
              count={20}
              separator=","
              suffix="+"
              title="Programming Languages"
              description="With a passion for versatile problem-solving"
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <DefaultCounterCard
              count={1200}
              separator=","
              suffix="+"
              title="Hours"
              description="That meets quality standards required by our users"
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <DefaultCounterCard
              count={24}
              suffix="/7"
              title="Support"
              description="Actively engage team members that finishes on time"
            />
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Featuring;
