
import MKTypography from "components/MKTypography";
import { Instagram, LinkedIn } from "@mui/icons-material";

const date = new Date().getFullYear();

export default {
  brand: {
    name: "KobCon",
    // image: logoCT,
    route: "/",
  },
  socials: [
    
    {
      icon: <Instagram />,
      link: "https://www.instagram.com/kobcon_/",
      
    },
    {
      icon: <LinkedIn />,
      link: "https://www.linkedin.com/in/mohamad-kobeissi-05223915a/",
    },
  ],
  menus: [
    {
      name: "",
      items: [
        { name: "about us", href: "" },
      ]
    },
    {
      
      items: [
        { name: "illustrations", href: "" },
        
      ],
    },
    {
     
      items: [
        { name: "contact us", href: "" },
       
      ],
    },
    {
     
      items: [
        { name: "terms & conditions", href: "" },
        
      ],
    },
  ],
  copyright: (
    <MKTypography variant="button" fontWeight="regular">
      All rights reserved. Copyright &copy; {date} KobCon 
     
      .
    </MKTypography>
  ),
};
