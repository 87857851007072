import React from "react";
import myGif from 'assets/images/sendingMessage.gif'
function LoadingGif() {
  return (
    <div>
      <img
        src={myGif}
        className="flex items-center justify-center h-screen"
        alt="Loading..."
        width="200" // Set the width and height according to your GIF size
        height="200"
      />
    </div>
  );
}

export default LoadingGif;
