
import AboutUs from "layouts/pages/landing-pages/about-us";
import Impressum  from "layouts/pages/landing-pages/impressum"

const routes = [
  
  
          {
            // name: "about us",
            route: "/pages/landing-pages/about-us",
            component: <AboutUs />,
          },
          {
            // name: "about us",
            route: "/pages/landing-pages/impressum",
            component: <Impressum />,
          },
     
  ]


export default routes;
